import React from 'react';
import { Layout, SectionGallery } from '@websites/components';
import { YellowBusinessContext } from '../contexts/YellowBusinessContext';

function GalleryPage() {
  const { yellowBusiness, sections } = React.useContext(YellowBusinessContext);

  const hasTheme = !!yellowBusiness?.website?.theme;

  return (
    <>
      {hasTheme && (
        <Layout
          yellowBusiness={yellowBusiness}
          sections={sections}
          pageName={sections?.gallerySection?.name}
        >
          <SectionGallery
            section={sections?.gallerySection}
            yellowBusiness={yellowBusiness}
          />
        </Layout>
      )}
    </>
  );
}

export default GalleryPage;
